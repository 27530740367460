<template>
  <main class="relative">
    <CategoryDateHeader v-if="selectedDates" :selected-dates="selectedDates" />

    <div class="py-5">
      <div v-if="categories" class="flex flex-col gap-6">
        <CategoryResults
          v-for="category in categories"
          :key="category.id"
          :value="category"
          :loading="!categoriesInitialized"
          :max-items="5"
          :filters="getQueryFromFilters()"
        />
      </div>
      <CategoryResults v-else :loading="!categoriesInitialized" />
    </div>
    <UiEmptySearch
      v-if="categories && categories.length === 0"
      class="absolute h-full justify-center inset-center"
    />
  </main>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { onBeforeMount, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import { CategoryDateHeader, CategoryResults } from '@/3_widgets/Category'
import { type CategoryFilters, useCategory } from '@/5_entities/Category'
import { usePageFilters } from '@/6_shared/composables'
import { UiEmptySearch } from '@/6_shared/ui'

const { query } = useRoute()
const { filters, updateDateFilters, getQueryFromFilters } = usePageFilters<CategoryFilters>(5)
const selectedDates = shallowRef<Undefinable<Date | Date[]>>(undefined)
const setDateFromQuery = () => {
  if (!query) return
  const { fromDateTime, tillDateTime } = query
  if (!fromDateTime) return
  const firstDate = new Date(fromDateTime as string)
  const lastDate = tillDateTime && new Date(tillDateTime as string)

  selectedDates.value = lastDate ? [firstDate, lastDate] : firstDate

  updateDateFilters(lastDate ? [firstDate, lastDate] : firstDate)
}

const { categories, categoriesInitialized } = storeToRefs(useCategory())
const { getAllCategories } = useCategory()

onBeforeMount(() => {
  setDateFromQuery()
  getAllCategories(filters.value)
})
</script>
